import { useCookies } from "vue3-cookies";
import axios from "axios";

class CardService {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
    const { cookies } = useCookies();
    this.cookies = cookies;
  }

  /**
   * Reload card.
   *
   * @returns {Promise<null|boolean>}
   * @param cardId
   * @param customerId
   * @param profileTypeId
   */
  async reloadCard(cardId, customerId, profileTypeId) {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.post(
        this.baseUrl + "/scanner/cards/" + cardId + "/reload",
        {
          customer_id: customerId,
          profile_type_id: profileTypeId,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server." + error);
    }
  }

  /**
   * Invalidate a card.
   *
   * @param cardId
   * @returns {Promise<null|boolean>}
   */
  async invalidateCard(cardId) {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.post(
        this.baseUrl + "/scanner/cards/" + cardId + "/invalidate",
        {},
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        return true;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Check card.
   *
   * @param cardId
   * @returns {Promise<null|boolean>}
   */
  async checkCard(cardId) {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.get(this.baseUrl + "/scanner/cards/" + cardId, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Fetch profile types.
   *
   * @returns {Promise<*[]|any>}
   */
  async getProfileTypes() {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.get(this.baseUrl + "/scanner/profile-types", {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server." + error);
    }
  }
}

export default CardService;
