import axios from "axios";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

class authService {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_URL;
    const { cookies } = useCookies();
    this.cookies = cookies;
    this.store = useStore();
    this.router = useRouter();
  }

  /**
   * Check if user is logged in.
   */
  checkAuth() {
    const authToken = this.cookies.get("access_token");
    if (authToken) {
      // Shitty hack, because user needs to be set immediately.
      const user = this.me();
      this.store.commit("auth/setUser", user);
      return user;
    }
    return null;
  }

  /**
   * Login with email and password.
   *
   * @param email
   * @param password
   * @returns {Promise<boolean>}
   */
  async login(email, password) {
    const payload = {
      email,
      password,
      origin: "staff",
    };
    try {
      const response = await axios.post(this.baseUrl + "/auth/login", payload);
      this.setToken(response.data.token, response.data.expiration + "s");
      this.store.commit("auth/setUser", response.data.user);
      this.router.push("/");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        throw new Error("Benutzername oder Passwort ist falsch.");
      }
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Get currently logged in user.
   *
   * @returns {Promise<null|any>}
   */
  async me() {
    try {
      const authToken = this.cookies.get("access_token");
      const response = await axios.get(
        this.baseUrl + "/auth/me",
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      if (response.status === 200) {
        this.store.commit("auth/setUser", response.data);
        return response.data;
      }
      return null;
    } catch (error) {
      throw new Error("Es gab einen Fehler auf dem Server.");
    }
  }

  /**
   * Delete token.
   */
  logout() {
    this.deleteToken();
    this.store.commit("auth/setUser", null);
    this.router.push("/login");
  }

  /**
   * Set auth token in local storage.
   *
   * @param {string} token
   * @param ttl
   */
  setToken(token, ttl = "2592000s") {
    if (!this.cookies.get("access_token")) {
      this.cookies.set("access_token", token, ttl, null);
    }
  }

  /**
   * Delete auth token in local storage.
   */
  deleteToken() {
    this.cookies.remove("access_token", "/");
  }
}

export default authService;
