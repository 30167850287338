import Dashboard from "../views/Dashboard";

export default [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: () => import("../views/Bookings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/check",
    name: "Check",
    component: () => import("../views/Check.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("../views/Customer.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      redirectAfterAuth: true,
    },
  },
];
