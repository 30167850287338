import { createStore } from "vuex";
import auth from "./auth";
import search from "./search";

export default createStore({
  state: {
    customer: null,
    profile: null,
    card: null,
  },
  mutations: {
    setCustomer(state, customer) {
      state.customer = customer;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setCard(state, card) {
      state.card = card;
    },
  },
  actions: {},
  modules: {
    auth,
    search,
  },
});
