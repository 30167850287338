export default {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
  },

  mutations: {
    /**
     * Set user.
     *
     * @param state
     * @param user
     */
    setUser(state, user) {
      state.user = user;
    },
  },
};
