<template>
  <div class="card-reader overlay">
    <div class="overlay-container">
      <font-awesome-icon
        icon="circle-notch"
        spin
        size="lg"
        class="mb-10"
      ></font-awesome-icon>
      <div class="logs-container mb-10" v-if="logs.length > 0">
        <div class="log" v-for="(log, index) in logs" :key="index">
          <font-awesome-icon
            icon="caret-right"
            class="mr-2"
          ></font-awesome-icon>
          {{ log }}
        </div>
      </div>
      <action-button
        label="Abbrechen"
        color="default"
        @action="stopScan()"
      ></action-button>
    </div>
  </div>
</template>

<script>
import ActionButton from "./form/ActionButton";

export default {
  name: "CardReader",

  components: {
    ActionButton,
  },

  data: () => ({
    logs: [],
    ndef: null,
    abortController: null,
  }),

  async mounted() {
    if ("NDEFReader" in window) {
      // Abort controller.
      this.abortController = new AbortController();
      this.abortController.signal.onabort = () => {
        //this.logs = [];
      };

      // Create NDEF reader.
      // eslint-disable-next-line no-undef
      this.ndef = new NDEFReader();
      this.ndef.onreadingerror = () => {
        this.logs.push("Der NFC Tag konnte nicht gelesen werden.");
        this.abortController.abort();
      };
      this.ndef.onreading = ({ serialNumber }) => {
        const number = this.hexToDecimal(serialNumber);
        this.logs.push("Hex: " + serialNumber);
        this.logs.push("Kartennummer: " + number);
        this.abortController.abort();
        this.$emit("success", number);
      };
      await this.ndef.scan({ signal: this.abortController.signal });
    } else {
      alert("NDEF ist nicht verfügbar");
    }
  },

  unmounted() {
    this.stopScan();
  },

  methods: {
    /**
     * Stop scan process.
     */
    stopScan() {
      this.abortController.abort();
      this.$emit("aborted");
    },

    /**
     * Hex decimal to decimal.
     *
     * @param hex
     * @returns {number}
     */
    hexToDecimal(hex) {
      hex = hex.replaceAll(":", "");
      return parseInt(hex, 16);
    },
  },
};
</script>

<style scoped></style>
