<template>
  <div class="fgg-container bg-accent-500">
    <h2 class="mb-0">Brauwelt Staff</h2>
  </div>
  <div class="fgg-container">
    <p>
      <b>Eingeloggt als:</b><br />
      {{ user.frist_name }} {{ user.last_name }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {},

  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>
