<template>
  <button
    v-if="!confirmationMode"
    :class="['btn', `btn-${color}`, classNames]"
    :disabled="loading || disabled"
    :type="type"
    @click="clickAction()"
  >
    <font-awesome-icon
      icon="spinner"
      spin
      class="mr-2"
      v-if="loading"
    ></font-awesome-icon>
    {{ label }}
  </button>
  <div v-else class="overlay">
    <div class="overlay-container">
      <div class="grid grid-cols-2 gap-3">
        <div class="col-span-2 mb-3">Vorgang fortführen?</div>
        <div class="col-span-1">
          <button class="btn btn-default text-base" @click="abort()">
            Nein
          </button>
        </div>
        <div class="col-span-1">
          <button class="btn btn-secondary text-base" @click="confirm()">
            Ja
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionButton",

  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "default",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    class: {
      type: String,
      default: "",
    },
    confirmation: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    confirmationMode: false,
  }),

  computed: {
    classNames() {
      return this.class;
    },
  },

  methods: {
    clickAction() {
      if (this.confirmation) {
        this.confirmationMode = true;
      } else {
        this.$emit("action");
      }
    },

    abort() {
      this.confirmationMode = false;
    },

    confirm() {
      this.$emit("action");
      this.confirmationMode = false;
    },
  },
};
</script>

<style lang="scss"></style>
