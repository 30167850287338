<template>
  <div class="application">
    <nav class="header-nav" v-if="isLoggedIn">
      <div class="flex">
        <div class="flex-1 text-left text-2xl">
          <router-link to="/">
            <img src="./assets/Logo.svg" class="h-10" alt="" />
          </router-link>
        </div>
        <div class="flex-initial text-right logout-container">
          <button class="btn btn-icon" @click="logout()">
            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
          </button>
        </div>
      </div>
    </nav>

    <router-view />

    <nav class="footer-nav" v-if="isLoggedIn">
      <router-link to="/search">
        <font-awesome-icon icon="search" class="mr-1"></font-awesome-icon>
        Suche
      </router-link>
      <button @click="isScanning = true">
        <font-awesome-icon icon="id-card" class="mr-1"></font-awesome-icon>
        Prüfen
      </button>
    </nav>

    <card-reader @success="check" @aborted="abort" v-if="isScanning" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import AuthService from "./services/authService";
import CardReader from "./components/CardReader";
import CardService from "./services/cardService";
import { POSITION, useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default {
  components: { CardReader },

  setup() {
    const authService = new AuthService();
    authService.checkAuth();

    return {
      authService,
      cardService: new CardService(),
      toast$: useToast(),
      router: useRouter(),
    };
  },

  data: () => ({
    isScanning: false,
  }),

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
  },

  methods: {
    ...mapMutations("auth", ["setUser"]),
    ...mapMutations(["setCustomer", "setProfile", "setCard"]),

    /**
     * Logout
     */
    logout() {
      this.authService.logout();
    },

    /**
     * Check read card.
     *
     * @param cardId
     */
    async check(cardId) {
      try {
        const card = await this.cardService.checkCard(cardId);
        if (card && card.customer) {
          this.setProfile(card.active_profile);
          this.setCard({ id: card.id });
          this.setCustomer(card.customer);
          this.abort();
          this.router.push(`/customer`);
        } else {
          this.abort();
          this.toast$.info("Die Karte ist niemandem zugewiesen.", {
            position: POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        this.abort();
        this.toast$.error("Die Karte ist unbekannt.", {
          position: POSITION.BOTTOM_CENTER,
        });
      }
    },

    /**
     * Abort scan.
     */
    abort() {
      this.isScanning = false;
    },
  },
};
</script>

<style lang="scss">
body,
html {
  @apply bg-accent-200;
}

.application {
  @apply mt-14;
  @apply mb-14;
  @apply text-primary-400;

  nav.header-nav {
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply w-full;
    @apply py-2;
    @apply px-3;
    @apply bg-primary-400;
    @apply text-white;
    @apply font-klint-bold;

    -webkit-box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);

    .logout-container {
      margin-top: 6px;
    }
  }

  nav.footer-nav {
    @apply fixed;
    @apply bottom-0;
    @apply left-0;
    @apply w-full;
    @apply py-3;
    @apply bg-primary-400;
    @apply text-white;
    @apply font-klint-regular;
    @apply text-center;
    @apply text-base;
    -webkit-box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);

    a,
    button {
      @apply inline-block;
      @apply px-4;
    }
  }
}
</style>
