export default {
  namespaced: true,

  state: {
    results: [],
  },

  mutations: {
    /**
     * Set user.
     *
     * @param state
     * @param results
     */
    setResults(state, results) {
      state.results = results;
    },
  },
};
