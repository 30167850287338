import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Validate meta fields (authentication rules)
router.beforeEach((to, from, next) => {
  // Check if authentication is required
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next();
      return;
    }
    next("/login");
    return;
  }

  // Check if a redirection is expected
  if (to.matched.some((record) => record.meta.redirectAfterAuth)) {
    if (store.getters["auth/isLoggedIn"]) {
      next("/");
      return;
    }
  }

  next();
});

export default router;
